import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "round-counter-container" }
const _hoisted_2 = { class: "round-counter" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", null, _toDisplayString($props.num), 1)
    ])
  ]))
}