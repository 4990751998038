import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "help-ordered-section-item" }
const _hoisted_2 = { class: "ordered-section-header" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "ordered-section-details" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_UIRoundCounter = _resolveComponent("UIRoundCounter")
  const _component_UIPageDivider = _resolveComponent("UIPageDivider")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "header", {}, () => [
      _createElementVNode("div", _hoisted_2, [
        ($props.title || $props.stepNumber)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_UIRoundCounter, {
                num: $props.stepNumber,
                class: "help-ordered-section-counter"
              }, null, 8, ["num"]),
              _createElementVNode("h3", {
                class: _normalizeClass([{'counter-padding': $props.stepNumber}, "help-ordered-section-title"])
              }, _toDisplayString($props.title), 3)
            ]))
          : _createCommentVNode("", true)
      ])
    ], true),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    ($props.divider)
      ? (_openBlock(), _createBlock(_component_UIPageDivider, {
          key: 0,
          class: "ordered-section-divider"
        }))
      : _createCommentVNode("", true)
  ]))
}